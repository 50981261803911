import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Pages = ({data}) => {
  const page = data.markdownRemark
  return (
    <Layout>
      <SEO title={page.frontmatter.title} />
      <section className="page-section">
        <div className="grid-container">
          <div className="row">
            <div className="col-12">
              <h1 className={"title"}>{page.frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{__html: page.html}}/>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Pages

export const query = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
            }
        }
    }
`